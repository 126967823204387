import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ballImg from "../images/ball-cropped.jpg"
import ladiesImg from "../images/ladies.jpg"
import houseImg from "../images/steamboat-gothic.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="The Gothic Guild" />
    <img
      className="hero-img"
      src={ballImg}
      style={{ filter: "grayscale(1)" }}
    />
    <section>
      <div className="hidden anchor" id="about" tabIndex={-1}></div>
      <h1>About The Gothic Guild</h1>
      <p>
        The purpose of the Gothic Guild is to promote the cultural, educational,
        and historical preservation of Huntsville and Madison County.
      </p>
    </section>
    <img className="hero-img" src={ladiesImg} />
    <section>
      <div className="hidden anchor" id="fundraising" tabIndex={-1}></div>
      <h1>Fundraising</h1>
      <p>
        The Gothic Guild sponsors a Victorian Cotillion composed of local high
        school sophomore girls who are invited to participate in a series of
        etiquette training sessions which is the culmination of the girls'
        introduction at the Victorian Cotillion Dinner Dance. Proceeds from each
        Victorian Cotillion support a worthwhile community project in keeping
        with the Gothic Guild's purpose.
      </p>
      <p>Recipients of the Gothic Guild Victorian Cotillion Proceeds*</p>
      <ul>
        <li>Steamboat Gothic House - 1985</li>
        <li>Steamboat Gothic House - 1986</li>
        <li>Huntsville/Madison County Library Heritage Room - 1987</li>
        <li>Historic Huntsville Foundation - 1988</li>
        <li>Constitution Hall Park - 1989</li>
        <li>Burrit Museum - 1990</li>
        <li>Huntsville Museum of Art - 1991</li>
        <li>Huntsville Botanical Garden - 1992</li>
        <li>Alabama Constitution Village - 1993</li>
        <li>Historic Huntsville Foundation - 1994</li>
        <li>Huntsville Museum of Art - 1995</li>
        <li>Historic Huntsville Foundation - 1996</li>
        <li>Burritt Museum - 1997</li>
        <li>Community Ballet Association - 1998</li>
        <li>Historic Huntsville Foundation - 1999</li>
        <li>Huntsville Pilgrimage Association - 2000</li>
        <li>The Land Trust of Huntsville and North Alabama - 2001</li>
        <li>The Weeden House - 2002</li>
        <li>Historic Huntsville Foundation - 2003</li>
        <li>Oak Place - 2004</li>
        <li>Community Ballet Association - 2005</li>
        <li>Early Works/Constitution Village - 2006</li>
        <li>Lincoln Village Preservation Corporation - 2007</li>
        <li>Merrimack Hall Performing Arts Center - 2008</li>
        <li>The Weeden House - 2009</li>
        <li>Huntsville Youth Orchestra - 2010</li>
        <li>Community Ballet Association - 2011</li>
        <li>Burritt Museum - 2012</li>
        <li>Early Works Family of Museums - 2013</li>
        <li>The Weeden House - 2014</li>
        <li>Lincoln Village Ministries - 2015</li>
        <li>Early Works Family of Museums: A Bicentennial Exhibit - 2016</li>
        <li>Huntsville/Madison County Botanical Gardens - 2017</li>
        <li>Burritt Museum - 2018</li>
        <li>Huntsville Ballet Association - 2019</li>
      </ul>
      <p>*Contributions to date total over $749,000.00</p>
    </section>
    <section>
      <div className="hidden anchor" id="history" tabIndex={-1}></div>
      <h1>History</h1>
      <p>
        The Gothic Guild derives its name from a residential steamboat-style
        structure built on Franklin Street in Huntsville by Captain John Van
        Valkenburgh in 1885. In 1973 the structure was moved to 205 Lowe Avenue
        in the Twickenham Historic Preservation District to become the
        headquarters for the Madison County Federation of Women's Clubs. funds
        from the Gothic Guild supported the restoration and preservation of the
        Steamboat Gothic House until its sale in 1987.
      </p>
    </section>
    <figure>
      <a href="http://huntsvillehistorycollection.org/hh/index.php?title=Lowe_Avenue,_Twickenham">
        <img className="hero-img small" src={houseImg} />
      </a>
      <figcaption>
        <a href="http://huntsvillehistorycollection.org/hh/index.php?title=Lowe_Avenue,_Twickenham">
          Learn More about the Steamboat Gothic House
        </a>
      </figcaption>
    </figure>
  </Layout>
)

export default IndexPage
